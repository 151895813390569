<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="8" sm="12" md="8">
        <v-container fluid>
          <v-row class="justify-space-between" no-gutters>
            <v-col cols="12" lg="8" md="8" sm="8" xs="6">
              <p class="title font-weight-bold ma-0">Customer Complaints</p>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="4">
              <div class="float-right">
                <CreateComplaint
                  :isEditComplaint="false"
                  @refreshComponent="refreshComponent"
                  :key="createComplaintKey"
                ></CreateComplaint>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid mt-0 mb-0 pt-0 pb-0>
          <v-row>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchComplaintNumber"
                label="Complaint Number"
                append-icon="search"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchCallerName"
                label="Caller Name"
                append-icon="search"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-text-field
                v-model="searchComplaintLocation"
                label="Complaint Location"
                append-icon="search"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-combobox
                v-model="searchAssignedTo"
                :items="getUserList"
                item-value="fullname"
                item-text="fullname"
                label="Assigned To"
                clearable
                outlined
                dense
                v-on:change="onSearchAssignedTo"
              ></v-combobox>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="searchComplaintDate"
                    outlined
                    dense
                    clearable
                    label="Complaint Date"
                    persistent-hint
                    append-icon="event"
                    readonly
                    v-on="on"
                    @click:clear="searchComplaintDate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchComplaintDate"
                  :color="getColorScheme.darkColor"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid pt-0 pb-0>
          <v-row>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="12"
              xs="12"
              class="pa-0 pl-3 subtitle-1 font-weight-bold"
              >List of Complaints
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" xs="12" class="pa-0 pl-3">
              <v-switch
                dense
                v-model="includeClosedComplaints"
                class="mt-0 mb-0"
                :color="getColorScheme.darkColor"
                label="Closed Complaints"
              ></v-switch>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="pa-0 pl-3">
              <v-row
                class="justify-space-between"
                v-if="complaintCheckboxSelected"
              >
                <v-col cols="4" class="pa-0 pl-3">
                  <v-combobox
                    v-model="assignTo"
                    :items="getUserList"
                    item-value="fullname"
                    item-text="fullname"
                    label="Assign To"
                    clearable
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="8" class="pa-0 pl-3">
                  <v-btn
                    :color="getColorScheme.darkColor"
                    dark
                    small
                    raised
                    v-on:click="
                      updateComplaintForAssignTo(
                        selectedComplaintNums,
                        assignTo,
                        getUser.fullname,
                        getUser.email
                      )
                    "
                    >Go
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid style="max-height: 68vh" class="overflow-y-auto">
          <v-row no-gutters>
            <v-col>
              <v-expansion-panels
                focusable
                accordion
                hover
                tile
                v-model="openPanelIndex"
              >
                <v-expansion-panel
                  v-for="(complaint, i) in filteredComplaints"
                  :key="i"
                >
                  <v-expansion-panel-header
                    class="pa-0"
                    style="font-size: 0.9em;"
                  >
                    <template>
                      <v-row no-gutters align="center">
                        <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                          <v-layout align-center justify-left>
                            <v-checkbox
                              dense
                              hide-details
                              class="mt-0 mb-1 ml-3"
                              :color="getColorScheme.darkColor"
                              :value="complaint._id"
                              v-model="selectedComplaintNums"
                              @click.native="selectComplaint($event)"
                            ></v-checkbox>
                            <Strong>Complaint #:</Strong>
                            {{ complaint.complaintNumber }}
                          </v-layout>
                        </v-col>
                        <v-col cols="12" lg="5" md="5" sm="12" xs="12">
                          <Strong>Complaint Date:</Strong>
                          {{
                            $moment(complaint.complaintDate).format(
                              "MMM Do YYYY"
                            )
                          }}, {{ complaint.complaintTime }}
                        </v-col>
                        <v-col cols="12" lg="2" md="2" sm="12" xs="12">
                          <v-icon
                            :color="
                              getStatusColor(
                                complaint.complaint_status.statusCode
                              )
                            "
                            >info
                          </v-icon>
                        </v-col>
                        <v-col cols="12" lg="1" md="1" sm="12" xs="12">
                          <v-row
                            v-if="complaint.complaint_status.statusCode != 4"
                          >
                            <v-col cols="2">
                              <CreateComplaint
                                :complaintEdit="complaint"
                                :isEditComplaint="true"
                                :key="editComplaintKey"
                              ></CreateComplaint>
                            </v-col>
                            <v-col cols="2">
                              <v-icon
                                class="mr-2"
                                color="pink"
                                small
                                @click.native="
                                  confirmDeleteComplaint(
                                    $event,
                                    complaint._id,
                                    complaint.complaintNumber
                                  )
                                "
                                >mdi-delete
                              </v-icon>
                            </v-col>
                            <v-col cols="2">
                              <v-icon
                                v-if="getUser.role.type == 'admin'"
                                class="mr-2"
                                color="purple"
                                small
                                @click.native="
                                  confirmCloseComplaint($event, complaint)
                                "
                                >mdi-close-circle
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                        xs="12"
                        class="pl-0 pb-0"
                      >
                        <v-card
                          class="mx-auto"
                          :color="getColorScheme.lightColor"
                          height="100%"
                        >
                          <v-card-text class="pb-0" style="font-size: 0.8em;">
                            <v-row no-gutters>
                              <v-col>
                                <p class="subtitle-1 font-weight-bold">
                                  Caller Details
                                </p>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Name:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.callerName }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Phone:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.callerPhone }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Email:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.callerEmail }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Address:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.callerAddress }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col>
                                <p class="subtitle-1 font-weight-bold">
                                  Complaint Details
                                </p>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Type:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.complaint_type.type }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Location:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.complaintLocation.address }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Description:</b>
                              </v-col>
                              <v-col>
                                {{ complaint.description }}
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3">
                                <b>Date:</b>
                              </v-col>
                              <v-col>
                                {{
                                  $moment(complaint.complaintDate).format(
                                    "MMM Do YYYY"
                                  )
                                }},
                                {{ complaint.complaintTime }}
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        lg="8"
                        md="8"
                        sm="12"
                        xs="12"
                        class="pr-0 pb-0"
                      >
                        <v-card
                          class="mx-auto"
                          :color="getColorScheme.lightColor"
                          height="100%"
                        >
                          <v-card-text
                            v-if="complaint.assignedTo"
                            class="pb-0"
                            style="font-size: 0.8em;"
                          >
                            <v-row no-gutters>
                              <v-col>
                                <p class="subtitle-1 font-weight-bold">
                                  Investigation Details
                                </p>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="7">
                                <b>Assigned Date:</b>
                                {{
                                  $moment(complaint.assignedDate).format(
                                    dateTimeFormat
                                  )
                                }}
                              </v-col>
                              <v-col>
                                <b>Assigned To:</b>
                                {{ complaint.assignedTo.fullname }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="pb-0">
                                <b>Investigation Notes:</b>
                                <p
                                  v-if="
                                    complaint.investigativenotes.length == 0
                                  "
                                >
                                  Not Available.
                                </p>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="pt-1">
                                <v-data-table
                                  dense
                                  :headers="headers"
                                  :items="complaint.investigativenotes"
                                  :items-per-page="5"
                                  :sort-by="['noteDateTime']"
                                  :sort-desc="[true]"
                                  class="elevation-1"
                                  v-if="complaint.investigativenotes.length > 0"
                                  :style="
                                    `background-color:${getColorScheme.lightColor};`
                                  "
                                >
                                  <template
                                    v-slot:[`item.createdAt`]="{ item }"
                                  >
                                    <v-row no-gutters align="center">
                                      <v-col
                                        cols="3"
                                        v-if="
                                          getUser.role.type == 'admin' &&
                                            complaint.complaint_status
                                              .statusCode != 4
                                        "
                                      >
                                        <v-btn icon>
                                          <v-icon
                                            color="pink"
                                            small
                                            @click.native="
                                              confirmDeleteNote($event, item)
                                            "
                                          >
                                            mdi-delete
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col class="caption" align="left">
                                        {{
                                          $moment(item.createdAt).format(
                                            dateTimeFormat
                                          )
                                        }}
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <template v-slot:[`item.note`]="{ item }">
                                    <v-row no-gutters align="center">
                                      <v-col class="caption" align="left">
                                        {{ item.note }}
                                      </v-col>
                                      <v-col
                                        cols="2"
                                        v-if="
                                          getUser.role.type == 'admin' &&
                                            complaint.complaint_status
                                              .statusCode != 4
                                        "
                                      >
                                        <v-btn icon>
                                          <v-icon
                                            color="teal"
                                            small
                                            @click.native="
                                              confirmEditNote(
                                                $event,
                                                item.id,
                                                item.note
                                              )
                                            "
                                          >
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <template v-slot:[`item.docs`]="{ item }">
                                    <v-row no-gutters align="center">
                                      <v-col class="caption" align="left">
                                        <span
                                          v-for="(doc, index) in item.docs"
                                          :key="index"
                                        >
                                          <FileDisplayDialog
                                            :doc="doc"
                                            :complaintStatusCode="
                                              complaint.complaint_status
                                                .statusCode
                                            "
                                          >
                                          </FileDisplayDialog>
                                        </span>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                            <p
                              class="error subtitle-2 font-weight-bold"
                              v-if="errorAddNote"
                            >
                              {{ errorAddNote }}
                            </p>
                            <v-row
                              no-gutters
                              v-if="complaint.complaint_status.statusCode != 4"
                            >
                              <v-col>
                                <v-text-field
                                  v-model="newInvestigativeNote"
                                  label="Add new note ..."
                                  prepend-icon="mdi-note"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                              no-gutters
                              v-if="complaint.complaint_status.statusCode != 4"
                            >
                              <v-col>
                                <v-file-input
                                  label="Upload Investigative Image/PDF/Document..."
                                  v-model="newInvestigativeDoc"
                                  outlined
                                  dense
                                  small-chips
                                  show-size
                                  multiple
                                ></v-file-input>
                              </v-col>
                            </v-row>
                            <v-row
                              no-gutters
                              v-if="complaint.complaint_status.statusCode != 4"
                            >
                              <v-col
                                align="right"
                                justify="center"
                                class="pb-2"
                              >
                                <v-btn
                                  :color="getColorScheme.darkColor"
                                  dark
                                  small
                                  raised
                                  v-on:click="uploadInvestigativeDoc(complaint)"
                                  >Save Note
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-text v-else>
                            <b>Status:</b>Not assigned.
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialogDeleteConfirm"
            v-if="deleteComplaintNum"
            persistent
            max-width="425"
          >
            <v-card>
              <v-card-title class="headline">Delete Complaint?</v-card-title>
              <v-card-text>
                Are you sure you want to delete complaint #{{
                  deleteComplaintNum
                }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  dark
                  small
                  raised
                  @click="dialogDeleteConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="deleteComplaint(deleteComplaintId)"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDeleteNoteConfirm"
            persistent
            max-width="425"
          >
            <v-card>
              <v-card-title class="headline">Delete Note?</v-card-title>
              <v-card-text>
                Are you sure you want to delete this investigative note?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  dark
                  small
                  raised
                  @click="dialogDeleteNoteConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="deleteNote"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCloseConfirm"
            v-if="closeComplaintObj"
            persistent
            max-width="425"
          >
            <v-card>
              <v-card-title class="headline">Close Complaint?</v-card-title>
              <v-card-text>
                Are you sure you want to close complaint #{{
                  closeComplaintObj.complaintNumber
                }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  dark
                  small
                  raised
                  @click="dialogCloseConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="closeComplaint(closeComplaintObj)"
                  >Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEditNoteConfirm" persistent max-width="525">
            <v-card>
              <v-card-title
                class="title white--text"
                :style="`background-color:${getColorScheme.darkColor};`"
                >Edit Note
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="editNoteText"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  dark
                  small
                  raised
                  @click="dialogEditNoteConfirm = false"
                  >Cancel
                </v-btn>
                <v-btn
                  :color="getColorScheme.darkColor"
                  dark
                  small
                  raised
                  @click="editNote"
                  >Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-col>
      <v-col cols="12" lg="4" sm="12" md="4">
        <MapComponent
          :dataObj="filteredComplaints"
          titleField="complaintNumber"
          statusField="complaint_status"
          locationField="complaintLocation"
          @setOpenPanelIndex="setOpenPanelIndex"
        ></MapComponent>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CreateComplaint from "./create-complaint";
import { mapActions, mapState, mapGetters } from "vuex";
import * as constants from "../../shared/constants";
import FileDisplayDialog from "./file-display-dialog";
import MapComponent from "../map/map-component";

export default {
  name: "complaints",
  components: { CreateComplaint, FileDisplayDialog, MapComponent },
  props: {
    openComplaintId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      assignTo: "",
      menu1: false,
      selectedComplaintNums: [],
      searchComplaintNumber: "",
      searchCallerName: "",
      searchComplaintLocation: "",
      searchAssignedTo: "",
      searchComplaintDate: null,
      investigativenotes: [],
      newInvestigativeNote: "",
      newInvestigativeDoc: null,
      errorAddNote: "",
      dateTimeFormat: constants.DATE_TIME_FORMAT,
      dialogDeleteConfirm: false,
      deleteComplaintId: null,
      deleteComplaintNum: null,
      dialogCloseConfirm: false,
      dialogDeleteNoteConfirm: false,
      deleteObj: null,
      dialogEditNoteConfirm: false,
      editNoteText: null,
      closeComplaintObj: null,
      complaintDetailsKey: 1,
      createComplaintKey: 1,
      editComplaintKey: 1,
      includeClosedComplaints: false,
      openPanelIndex: -1,
      headers: [
        {
          text: "Date/Time",
          value: "createdAt",
          align: "center",
          sortable: false,
          width: "30%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Note Description",
          value: "note",
          align: "center",
          sortable: false,
          width: "45%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
        {
          text: "Document",
          value: "docs",
          align: "center",
          sortable: false,
          width: "25%",
          class: "grey darken-3 white--text",
          mobile: true,
        },
      ],
    };
  },
  async created() {
    await this.getComplaintCategoriesAction();
    await this.getComplaintsAction();

    // In case coming from global search
    if (this.openComplaintId != undefined) {
      this.openPanelIndex = this.complaints.findIndex(
        (comp) => comp.id == this.openComplaintId
      );
      this.includeClosedComplaints = true;
    }
  },
  methods: {
    ...mapActions([
      "getComplaintsAction",
      "updateComplaintForAssignToAction",
      "updateComplaintForUploadFileAction",
      "getComplaintCategoriesAction",
      "deleteComplaintAction",
      "deleteInvestigativeNoteAction",
      "editInvestigativeNoteAction",
      "closeComplaintAction",
    ]),
    getStatusColor(statusCode) {
      if (statusCode == 1) {
        return "red";
      } else if (statusCode == 2) {
        return "orange";
      } else if (statusCode == 3) {
        return "green";
      } else if (statusCode == 4) {
        return "purple";
      }
    },
    onSearchAssignedTo(assignee) {
      if (assignee != null) {
        this.searchAssignedTo = assignee;
      } else {
        this.searchAssignedTo = "";
      }
    },
    selectComplaint: function(e) {
      e.cancelBubble = true;
    },
    confirmDeleteComplaint: function(e, deleteComplaintId, deleteComplaintNum) {
      e.cancelBubble = true;
      this.dialogDeleteConfirm = true;
      this.deleteComplaintId = deleteComplaintId;
      this.deleteComplaintNum = deleteComplaintNum;
    },
    async deleteComplaint(deleteComplaintId) {
      this.dialogDeleteConfirm = false;
      await this.deleteComplaintAction(deleteComplaintId);
    },
    confirmDeleteNote: function(e, deleteObj) {
      e.cancelBubble = true;
      this.dialogDeleteNoteConfirm = true;
      this.deleteObj = deleteObj;
    },
    async deleteNote() {
      this.dialogDeleteNoteConfirm = false;
      let dataObj = {
        noteId: this.deleteObj.id,
        docs: this.deleteObj.docs,
      };
      await this.deleteInvestigativeNoteAction(dataObj);
    },
    confirmEditNote: function(e, editInvestigativeNoteId, editNoteText) {
      e.cancelBubble = true;
      this.dialogEditNoteConfirm = true;
      this.editInvestigativeNoteId = editInvestigativeNoteId;
      this.editNoteText = editNoteText;
    },
    async editNote() {
      this.dialogEditNoteConfirm = false;
      let dataObj = {
        editInvestigativeNoteId: this.editInvestigativeNoteId,
        editNoteText: this.editNoteText,
      };
      await this.editInvestigativeNoteAction(dataObj);
    },
    confirmCloseComplaint: function(e, complaint) {
      e.cancelBubble = true;
      this.dialogCloseConfirm = true;
      this.closeComplaintObj = complaint;
    },
    async closeComplaint(complaint) {
      this.dialogCloseConfirm = false;
      let dataObj = {
        complaintId: complaint._id,
        complaintNum: complaint.complaintNumber,
        callerEmail: complaint.callerEmail,
      };
      if (complaint.complaint_status.statusCode > 1) {
        dataObj.assignedToEmail = complaint.assignedTo.email;
      }
      await this.closeComplaintAction(dataObj);
    },
    refreshComponent: function() {
      this.createComplaintKey++;
      this.editComplaintKey++;
    },
    setOpenPanelIndex(openPanelIndex) {
      this.openPanelIndex = openPanelIndex;
    },
    async uploadInvestigativeDoc(complaint) {
      if (!this.newInvestigativeNote) {
        this.errorAddNote = "Please add note.";
        return;
      }
      if (
        this.newInvestigativeDoc != null &&
        this.newInvestigativeDoc.size > 1024 * 1024 * 10
      ) {
        this.errorAddNote = "File too big (> 10MB)";
        return;
      } else {
        this.errorAddNote = "";
      }
      let dataObj = {
        complaintId: complaint._id,
        complaintStatus: complaint.complaint_status.status,
        note: this.newInvestigativeNote,
        files: this.newInvestigativeDoc,
      };
      await this.updateComplaintForUploadFileAction(dataObj);
      this.newInvestigativeNote = "";
      this.newInvestigativeDoc = null;
    },
    async updateComplaintForAssignTo(
      selectedComplaintNums,
      assignTo,
      getUserName,
      getUserEmail
    ) {
      if (!assignTo || selectedComplaintNums.length == 0) {
        this.assignTo = "";
        return;
      }
      let dataObj = {
        complaintIds: selectedComplaintNums,
        assignedTo: assignTo._id,
        assignedToEmail: assignTo.email,
        assignedToName: assignTo.fullname,
        assignedByName: getUserName,
        assignedByEmail: getUserEmail,
      };
      await this.updateComplaintForAssignToAction(dataObj);
      this.assignTo = "";
    },
  },
  computed: {
    ...mapState(["complaints"]),
    ...mapGetters(["getUser", "getUserList", "getColorScheme"]),

    complaintCheckboxSelected: function() {
      return this.selectedComplaintNums.length > 0 ? true : false;
    },

    filteredComplaints: function() {
      this.selectedComplaintNums = [];
      if (this.searchComplaintDate == null) {
        this.searchComplaintDate = "";
      }
      let allowedStatus = [1, 2, 3];
      if (this.includeClosedComplaints) {
        allowedStatus = [1, 2, 3, 4];
      }

      let filteredComplaints = this.complaints.filter((complaint) => {
        if (
          complaint != null &&
          complaint != undefined &&
          complaint.complaintNumber
            .toUpperCase()
            .match(this.searchComplaintNumber.toUpperCase()) &&
          complaint.callerName
            .toUpperCase()
            .match(this.searchCallerName.toUpperCase()) &&
          complaint.complaintLocation.address
            .toUpperCase()
            .match(this.searchComplaintLocation.toUpperCase()) &&
          complaint.complaintDate.match(this.searchComplaintDate) &&
          allowedStatus.includes(complaint.complaint_status.statusCode)
        ) {
          if (this.searchAssignedTo == "") {
            return complaint;
          } else if (
            complaint.assignedTo != undefined &&
            complaint.assignedTo._id == this.searchAssignedTo.id
          ) {
            return complaint;
          }
        }
      });
      return filteredComplaints;
    },
  },
};
</script>
<style>
.cluster-tile {
  margin: 0px 5px;
  border-radius: 4px;
}
.cluster-tile:hover {
  background: grey;
}
</style>
