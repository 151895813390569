<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog1" width="65%">
        <template v-slot:activator="{ on }">
          <v-row no-gutters align="center">
            <v-col cols="2">
              <v-btn icon>
                <v-icon
                  color="primary"
                  v-if="doc.mime == 'image/jpeg' || doc.mime == 'image/png'"
                  v-on="on"
                  >mdi-camera-image
                </v-icon>
                <v-icon
                  color="#B00020"
                  v-else-if="doc.mime == 'application/pdf'"
                  v-on="on"
                  >mdi-file-pdf-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="pl-2">
              {{ doc.name }}
            </v-col>
            <v-col
              cols="3"
              v-if="getUser.role.type == 'admin' && complaintStatusCode != 4"
            >
              <v-btn icon>
                <v-icon
                  color="pink"
                  small
                  @click.native="confirmDeleteDoc($event, doc.id, doc.name)"
                  >mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-card justify="center">
          <v-card-title class="ma-0 pa-0">
            {{ doc.name }}
            <v-spacer></v-spacer>
            <v-btn
              :color="getColorScheme.darkColor"
              dark
              small
              raised
              @click="dialog1 = false"
              >Close
            </v-btn>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <v-img
              v-if="doc.mime == 'image/jpeg' || doc.mime == 'image/png'"
              :src="`${doc.url}`"
              width="100%"
            />
            <iframe
              v-if="doc.mime == 'application/pdf'"
              :src="`${doc.url}`"
              scrolling="no"
              width="100%"
              height="800px"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" v-if="dialogDeleteDocConfirm">
      <v-dialog v-model="dialogDeleteDocConfirm" persistent max-width="425">
        <v-card>
          <v-card-title class="headline">Delete Document?</v-card-title>
          <v-card-text>
            Are you sure you want to delete investigative document :
            {{ deleteDocName }} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-grey"
              dark
              small
              raised
              @click="dialogDeleteDocConfirm = false"
              >Cancel
            </v-btn>
            <v-btn
              :color="getColorScheme.darkColor"
              dark
              small
              raised
              @click="deleteDoc"
              >Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { API } from "../../shared/config";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "file-display-dialog",
  props: {
    doc: {
      type: Object,
      required: false,
    },
    complaintStatusCode: Number,
  },
  data() {
    return {
      dialog1: false,
      api: API,
      dialogDeleteDocConfirm: false,
      deleteMediaDocId: null,
      deleteDocName: null,
    };
  },
  computed: {
    ...mapGetters(["getUser", "getColorScheme"]),
  },
  methods: {
    ...mapActions(["deleteInvestigativeDocAction"]),
    confirmDeleteDoc: function(e, deleteMediaDocId, deleteDocName) {
      e.cancelBubble = true;
      this.dialogDeleteDocConfirm = true;
      this.deleteMediaDocId = deleteMediaDocId;
      this.deleteDocName = deleteDocName;
    },
    async deleteDoc() {
      this.dialogDeleteDocConfirm = false;
      await this.deleteInvestigativeDocAction(this.deleteMediaDocId);
    },
  },
};
</script>
