<template>
  <div>
    <v-menu
      v-model="showClusterMarkers"
      :position-x="xMouseClickPosition"
      :position-y="yMouseClickPosition"
      absolute
    >
      <v-list dark>
        <v-list-item
          class="cluster-tile"
          v-for="(item, index) in clusteredMarkers"
          :key="index"
          @click="showDetails(item.panelSequence)"
        >
          <v-list-item-content>
            <v-list-item-title class="ma-0">
              <v-row no-gutters align="center">
                <v-col cols="11" style="font-size: 0.8em;">
                  {{ item.title }}
                </v-col>
                <v-col cols="1">
                  <v-img :src="item.icon"></v-img>
                </v-col>
              </v-row>
            </v-list-item-title>
            <v-list-item-subtitle style="font-size: 0.9em;">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <google-map
      :center="{ lat: 52.7765, lng: -108.299 }"
      :zoom="14"
      style="width: 100%; height: 90vh"
    >
      <google-cluster
        :gridSize="50"
        :options="{ ignoreHidden: false }"
        @click="clickOnCluster($event)"
      >
        <google-map-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :title="m.title"
          :icon="m.icon"
          @click="showDetails(m.index)"
        />
      </google-cluster>
    </google-map>
  </div>
</template>
<script>
export default {
  name: "map",
  props: {
    dataObj: {
      type: Array,
      required: true,
    },
    titleField: String,
    statusField: String,
    locationField: String,
  },
  data() {
    return {
      clusteredMarkers: null,
      xMouseClickPosition: null,
      yMouseClickPosition: null,
      showClusterMarkers: false,
    };
  },
  methods: {
    showDetails: function(panelSequence) {
      this.$emit("setOpenPanelIndex", parseInt(panelSequence));
    },
    clickOnCluster: function(event) {
      this.xMouseClickPosition = window.event.clientX;
      this.yMouseClickPosition = window.event.clientY;
      this.clusteredMarkers = event.getMarkers().map((marker) => {
        return {
          title: marker.title,
          panelSequence: this.markers.find((el) => el.title == marker.title)
            .index,
          subtitle: this.markers.find((el) => el.title == marker.title).address,
          icon: marker.icon,
        };
      });
      this.showClusterMarkers = true;
    },
  },
  computed: {
    markers: function() {
      let newObj = [];
      Object.entries(this.dataObj).map((entry, index) => {
        let location = entry[1][this.locationField];
        let imgPath =
          "https://res.cloudinary.com/lexcom/image/upload/v1596851145/site";

        if (location != undefined && location.position != undefined) {
          let value = {};
          let statusField = entry[1][this.statusField];

          value["position"] = location.position;
          value["title"] = entry[1][this.titleField];
          value["index"] = index;
          value["address"] = location.address;

          if (statusField == undefined) {
            //In case of Parking Violation
            if (entry[1].isWarning) {
              value["icon"] = imgPath + "/orange-dot.png";
            } else {
              value["icon"] = imgPath + "/red-dot.png";
            }
          } else if (statusField.statusCode == 1) {
            value["icon"] = imgPath + "/red-dot.png";
          } else if (statusField.statusCode == 2) {
            value["icon"] = imgPath + "/orange-dot.png";
          } else if (statusField.statusCode == 3) {
            value["icon"] = imgPath + "/green-dot.png";
          } else if (statusField.statusCode == 4) {
            value["icon"] = imgPath + "/purple-dot.png";
          }

          newObj.push(value);
        }
      });
      return newObj;
    },
  },
};
</script>
