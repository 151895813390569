<template>
  <v-row justify="center" no-gutters>
    <v-col>
      <v-dialog v-model="dialog" persistent width="65vh">
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="isEditComplaint"
            v-on="on"
            class="mr-2"
            small
            color="teal"
            >mdi-pencil
          </v-icon>
          <v-btn
            v-else
            :color="getColorScheme.darkColor"
            small
            raised
            dark
            v-on="on"
          >
            <v-icon class="mr-1" small> mdi-plus </v-icon>
            Complaint
          </v-btn>
        </template>
        <v-card>
          <v-card-title
            v-if="isEditComplaint"
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >Edit Complaint
          </v-card-title>
          <v-card-title
            v-else
            class="title white--text"
            :style="`background-color:${getColorScheme.darkColor};`"
            >New Complaint
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row no-gutters class="pt-2">
                <v-col>
                  <p class="subtitle-1 font-weight-bold">Caller Details</p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="complaint.callerName"
                    label="Caller Name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <VuePhoneNumberInput
                    v-model="complaint.callerPhone"
                    default-country-code="CA"
                    no-example
                    clearable
                    size="sm"
                    :translations="{
                      countrySelectorLabel: '',
                      phoneNumberLabel: 'Caller Phone',
                    }"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="complaint.callerEmail"
                    label="Caller Email Address"
                    append-icon="mdi-email"
                    outlined
                    dense
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-text-field
                    v-model="complaint.callerAddress"
                    label="Caller Address"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters> </v-row>
              <v-row no-gutters class="pt-2">
                <v-col
                  ><p class="subtitle-1 font-weight-bold">Complaint Details</p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-if="isEditComplaint && getUser.role.type != 'admin'"
                        v-model="complaint.complaintDate"
                        outlined
                        dense
                        label="Complaint Date"
                        append-icon="event"
                        disabled
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="complaint.complaintDate"
                        outlined
                        dense
                        label="Complaint Date"
                        append-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="complaint.complaintDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="complaint.complaintDate"
                      :color="getColorScheme.darkColor"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="complaint.complaintTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-if="isEditComplaint && getUser.role.type != 'admin'"
                        v-model="complaint.complaintTime"
                        label="Complaint Time"
                        outlined
                        dense
                        disabled
                        append-icon="access_time"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="complaint.complaintTime"
                        label="Complaint Time"
                        readonly
                        outlined
                        dense
                        allowed-seconds="false"
                        append-icon="access_time"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="complaint.complaintTime"
                      full-width
                      v-if="menu2"
                      scrollable
                      :color="getColorScheme.darkColor"
                      :ampm-in-title="true"
                      @click:minute="$refs.menu2.save(complaint.complaintTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-combobox
                    v-model="selectedComplaintCategory"
                    :items="getComplaintCategories"
                    item-value="category"
                    item-text="category"
                    label="Complaint Category"
                    outlined
                    dense
                    v-on:change="complaint.complaint_type = ''"
                  ></v-combobox>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-combobox
                    v-model="complaint.complaint_type"
                    :items="complaintTypes"
                    item-value="type"
                    item-text="type"
                    label="Complaint Type"
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2 pb-6">
                  <google-autocomplete
                    placeholder="   Complaint Location"
                    @place_changed="setComplaintLocation"
                    class="complaint-location"
                    :value="complaint.complaintLocation.address"
                  >
                  </google-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-0 mb-0">
                <v-col>
                  <v-textarea
                    outlined
                    v-model="complaint.description"
                    label="Complaint Description"
                    dense
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="isEditComplaint"
                      color="blue-grey"
                      small
                      dark
                      raised
                      @click="
                        dialog = false;
                        resetEditComplaint();
                      "
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-else
                      color="blue-grey"
                      small
                      dark
                      raised
                      @click="cancelComplaint()"
                      >Cancel
                    </v-btn>
                    <v-btn
                      v-if="isEditComplaint"
                      :color="getColorScheme.darkColor"
                      small
                      dark
                      raised
                      @click="editComplaint()"
                      >Save
                    </v-btn>
                    <v-btn
                      v-else
                      :color="getColorScheme.darkColor"
                      small
                      dark
                      raised
                      @click="saveComplaint()"
                      >Submit
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { gmapApi } from "vue2-google-maps";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapGetters } from "vuex";

export default {
  name: "create-complaint",
  components: { VuePhoneNumberInput },
  props: {
    complaintEdit: {
      type: Object,
      required: false,
    },
    isEditComplaint: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    complaint: {},
    dialog: false,
    place: null,
    menu1: "",
    menu2: "",
    selectedComplaintCategory: "",
    emailRules: [
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
  }),
  created() {
    if (this.isEditComplaint) {
      this.selectedComplaintCategory = this.getComplaintCategories
        .filter(
          (item) =>
            item.id == this.complaintEdit.complaint_type.complaint_categories[0]
        )
        .shift();
      this.resetEditComplaint();
    } else {
      this.complaint = {
        complaintNumber: "",
        callerName: "",
        callerPhone: "",
        callerAddress: "",
        callerEmail: "",
        complaint_type: "",
        description: "",
        complaintLocation: {},
        complaintDate: new Date().toISOString().substr(0, 10),
        complaintTime: new Date().getHours() + ":" + new Date().getMinutes(),
      };
    }
  },
  computed: {
    ...mapGetters(["getUser", "getComplaintCategories", "getColorScheme"]),
    google: gmapApi,
    complaintTypes: function() {
      let complaintTypes;
      if (
        this.selectedComplaintCategory != undefined &&
        this.selectedComplaintCategory != ""
      ) {
        complaintTypes = this.selectedComplaintCategory.types.map(
          (complaintType) => {
            return {
              _id: complaintType._id,
              type: complaintType.type,
            };
          }
        );
      }
      return complaintTypes;
    },
  },
  methods: {
    ...mapActions(["addComplaintAction", "editComplaintAction"]),
    setComplaintLocation(place) {
      let newObj = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        address: place.formatted_address,
      };
      this.complaint.complaintLocation = newObj;
    },
    cancelComplaint() {
      this.dialog = false;
      this.$emit("refreshComponent", true);
    },
    async saveComplaint() {
      this.dialog = false;
      this.complaint.statusUpdateDate = new Date();
      await this.addComplaintAction(this.complaint);
      this.$emit("refreshComponent", true);
    },
    async editComplaint() {
      this.dialog = false;
      await this.editComplaintAction(this.complaint);
      this.$emit("refreshComponent", true);
    },
    resetEditComplaint() {
      this.complaint = {
        _id: this.complaintEdit._id,
        complaintNumber: this.complaintEdit.complaintNumber,
        callerName: this.complaintEdit.callerName,
        callerAddress: this.complaintEdit.callerAddress,
        callerPhone: this.complaintEdit.callerPhone,
        callerEmail: this.complaintEdit.callerEmail,
        description: this.complaintEdit.description,
        complaintLocation: this.complaintEdit.complaintLocation,
        assignedDate: this.complaintEdit.assignedDate,
        assignedTo: this.complaintEdit.assignedTo,
        assignedBy: this.complaintEdit.assignedBy,
        complaintDate: this.complaintEdit.complaintDate,
        complaint_type: this.complaintEdit.complaint_type,
        complaint_status: this.complaintEdit.complaint_status,
        complaintTime: this.complaintEdit.complaintTime,
        complaintYear: this.complaintEdit.complaintYear,
        statusUpdateDate: this.complaintEdit.statusUpdateDate,
        investigativenotes: this.complaintEdit.investigativenotes,
      };
    },
  },
};
</script>
<style scoped>
.complaint-location {
  height: 32px;
  width: 100%;
  outline: none;
  border: 1px solid #808b96;
  border-radius: 4px;
  font-size: 0.9em;
  margin-bottom: 1%;
}
</style>
